<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/smartlodge/admin/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/smartlodge/admin/bookings' }">Bookings</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Bookings:</b></h3>
            <!-- {{ bookings }} -->
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Client Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div class="d-flex align-items-center" style="margin-left: 20px">
                <el-button class="mr-2" @click="exportToExcel" type="success">Export to Excel</el-button>
                <el-select
                  v-model="selectedFilter"
                  placeholder="Select"
                  class="d-block"
                  style="height: 40px !important"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading"
              class="table-responsive mb-5"
            >
              <!-- {{ formattedBookings }} -->
              <data-tables
                :data="formattedBookings"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getBookings"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load bookings Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No bookings</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="customer"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                  <span>Room: <strong>{{ scope.row.roomNumber }}</strong></span><br/>
                  <span>Name: <strong>{{ scope.row.fullName }}</strong></span><br/>
                  <span>No of Adults: <strong>{{ scope.row.numberOfAdults }}</strong></span><br/>
                  <span>No of Children: <strong>{{ scope.row.numberOfChildren }}</strong></span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="totalAmount"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span><strong></strong> ${{ formatNumber(scope.row.totalAmount) }} </span><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Time of Check In an Out"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <small>Check In Date: <strong>{{ formattedDate(scope.row.checkInDate) }}</strong></small><br />
                    <small>Check Out Date: <strong>{{ formattedDate(scope.row.checkOutDate) }}</strong></small><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="paymentStatus"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    {{ scope.row.paymentStatus }}
                  </template>
                </el-table-column>
                
                <el-table-column
                    label="bookingStatus"
                    :show-overflow-tooltip="false"
                    label-class-name="text-left"
                    class-name="text-left"
                >
                    <template slot-scope="scope">
                    {{ scope.row.bookingStatus }}
                    </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Download Booking"
                      placement="top"
                    >
                      <el-button @click="generateBookDownload(scope.row)" type="success" icon="el-icon-bottom" circle></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Details"
                      placement="top"
                    >
                    <el-button
                      @click="showReservationDetailsData(scope.row)"
                      type="primary"
                      :disabled="false"
                      icon="el-icon-view"
                      circle
                    ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                         type="danger"
                        :disabled="$store.state.userRoles !== 'SUPER_ADMIN'"
                        @click="deleteBooking(scope.row.bookingID)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
      

    <ReservationDetailsDialog
      :showReservationDetailsDialog="showReservationDetailsDialog"
      :reservationData="reservationData"
      @closeReservationDetailsDialog="closeReservationDetailsDialog"
      @re-fresh="getBookings"
    />
  
      <EditCountryDialog
        :showRoomEditDialog="showRoomEditDialog"
        :roomCategoryData="roomCategoryData"
        @closeRoomEditDialog="closeRoomEditDialog"
        @re-fresh="getCategories"
      />
  
    </div>
  </template>
  
  <script>
  import jsPDF from 'jspdf';
  import * as XLSX from "xlsx";
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import ReservationDetailsDialog from "../../components/backoffice/dialogs/booking-details.vue";
  import EditCountryDialog from "../../components/backoffice/dialogs/add-to-bookings-dialog";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
      ReservationDetailsDialog,
      EditCountryDialog
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        orderDetailsDialogVisible: false,
        bookings: [],
        order_data: {},
        showRoomEditDialog: false,
        roomCategoryData: {},
        showReservationDetailsDialog: false,
        reservationData: {},
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullName",
            value: "",
          },
        ],
        loadingError: false,
  
        selectedFilter: "Show All",
        filterOptions: [
          "Show All",
        "Show Confirmed",
        "Show pending"
        ],
        reviews: [],
        
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'], // same as { 2: '#99A9BF', 4: { value: '#F7BA2A', excluded: true }, 5: '#FF9900' }

        jsonData: [
          { id: 1, name: "John Doe", age: 25 },
          { id: 2, name: "Jane Smith", age: 30 },
        ], // Example JSON data
        bookingsExcelData: []
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
  
      formattedBookings() {
          
        if (this.selectedFilter === "Show All") {
          return this.bookings;
        } else if (this.selectedFilter === "Show Confirmed") {
          return this.bookings.filter((item) => {
            return item.bookingStatus === "CONFIRMED";
          });
        } else if (this.selectedFilter === "Show pending") {
          return this.bookings.filter((item) => {
            return item.bookingStatus === "pending";
          });
        }
        else {
          return this.bookings.filter((item) => {
            return item.bookingStatus === "pending";
          });
        }
      },
    },
  
    mounted() {
      this.getBookings();
    },
  
    methods: {
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
        
        showEditRoomCategoryData(roomCategoryData) {
          this.showRoomEditDialog = true;
          this.roomCategoryData = roomCategoryData;
        },
  
        closeRoomEditDialog() {
          this.showRoomEditDialog = false;
        },
  
      
      
        showReservationDetailsData(reservationData) {
        this.showReservationDetailsDialog = true;
        this.reservationData = reservationData;
      },

      closeReservationDetailsDialog() {
        this.showReservationDetailsDialog = false;
      },
  
      async getBookings() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          const token = JSON.parse(localStorage.getItem("user"));
          let request = await this.$http.get(`bookings`);
          if (
            request.data.success &&
            request.data.message === "BOOKINGS_FETCHED_SUCCESSFULLY"
          ) {
            // this.bookings = request.data.bookings;
            this.bookings = request.data.bookings.map(booking => ({
              fullName: `${booking.firstName} ${booking.lastName}`,
              bookingID: booking.bookingID,
              customerID: booking.customerID,
              roomID: booking.roomCategoryID,
              roomNumber: booking.roomNumber,
              checkInDate: booking.checkInDate,
              checkOutDate: booking.checkOutDate,
              bookingDate: booking.bookingDate,
              bookingStatus: booking.bookingStatus,
              paymentStatus: booking.paymentStatus,
              totalAmount: booking.totalAmount,
              numberOfAdults: booking.numberOfAdults,
              numberOfChildren: booking.numberOfChildren,
              specialRequests: booking.specialRequests,
              email: booking.email,
              phoneNumber: booking.phoneNumber,
              address: booking.address,
              country: booking.country,
              createdAt: booking.createdAt,
            }));
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Orders now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      

      generateBookDownload(reservationData) {
        const doc = new jsPDF();
        let yPos = 10; // Starting Y position in the PDF

        // Add title
        doc.setFontSize(16);
        doc.text("Customer Info", 10, yPos);
        yPos += 10;

        // Customer Info
        doc.setFontSize(12);
        doc.text(`Room: ${reservationData.roomNumber}`, 10, yPos);
        yPos += 6;
        doc.text(`Name: ${reservationData.fullName}`, 10, yPos);
        yPos += 6;
        doc.text(`Email: ${reservationData.email}`, 10, yPos);
        yPos += 6;
        doc.text(`Phone No: ${reservationData.phoneNumber}`, 10, yPos);
        yPos += 6;
        doc.text(`Address: ${reservationData.address}`, 10, yPos);
        yPos += 6;
        doc.text(`Country: ${reservationData.country}`, 10, yPos);
        yPos += 6;
        doc.text(`No. of Adults: ${reservationData.numberOfAdults}`, 10, yPos);
        yPos += 6;
        doc.text(`No. of Children: ${reservationData.numberOfChildren}`, 10, yPos);
        yPos += 10;

        // Booking Info
        doc.setFontSize(16);
        doc.text("Booking Info", 10, yPos);
        yPos += 10;

        doc.setFontSize(12);
        doc.text(`Total Amount: $${this.formatNumber(reservationData.totalAmount)}`, 10, yPos);
        yPos += 6;
        doc.text(`Check In Date: ${this.formattedDate(reservationData.checkInDate)}`, 10, yPos);
        yPos += 6;
        doc.text(`Check Out Date: ${this.formattedDate(reservationData.checkOutDate)}`, 10, yPos);
        yPos += 6;
        doc.text(`Time of Booking: ${this.formattedDate(reservationData.bookingDate)}`, 10, yPos);
        yPos += 10;

        // Booking Status
        doc.setFontSize(16);
        doc.text("Booking Status", 10, yPos);
        yPos += 10;

        doc.setFontSize(12);
        doc.text(`Status: ${reservationData.bookingStatus}`, 10, yPos);
        yPos += 10;

        // Payment Status
        doc.setFontSize(16);
        doc.text("Payment Status", 10, yPos);
        yPos += 10;

        doc.setFontSize(12);
        doc.text(`Status: ${reservationData.paymentStatus}`, 10, yPos);
        yPos += 10;

        // Special Requests
        doc.setFontSize(16);
        doc.text("Special Requests", 10, yPos);
        yPos += 10;

        doc.setFontSize(12);
        doc.text(reservationData.specialRequests, 10, yPos);
        yPos += 20;

        // Footer
        doc.setFontSize(10);
        doc.text("© 2024 Acacia Wilderness Mweya, All rights reserved.", 10, yPos);
        yPos += 5;

        // Save the PDF
        doc.save("booking.pdf");
      },


      exportToExcel() {
        this.bookingsExcelData = this.bookings.map((item) => ({
          FullName: item.fullName,
          RoomNumber: item.roomNumber,
          CheckInDate: this.formattedDate(item.checkInDate),
          CheckOutDate: this.formattedDate(item.checkOutDate),
          BookingStatus: item.bookingStatus,
          Payment: item.paymentStatus,
          Amount: `$${this.formatNumber(item.totalAmount)}`,
          People: `Adults: ${item.numberOfAdults} \nChildren: ${item.numberOfChildren}`, // Adding a newline in People field
          Email: item.email,
          Phone: item.phoneNumber,
          Address: `${item.address} ${item.country}`
        }));

        const worksheet = XLSX.utils.json_to_sheet(this.bookingsExcelData);

        // Enable wrap text for the People column
        Object.keys(worksheet).forEach((cell) => {
          if (worksheet[cell] && typeof worksheet[cell] === "object") {
            worksheet[cell].s = { alignment: { wrapText: true } };
          }
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");
        XLSX.writeFile(workbook, "Acacia_Wilderness_Mweya_Bookings.xlsx");
      },
    
      async deleteBooking(bookingID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Booking. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`bookings/${bookingID}`);
              if (
                request.data.success &&
                request.data.message === "BOOKING_DELETED_SUCCESSFULLY"
              ) {this.getBookings();
                this.$notify({
                  title: "Success",
                  message: "Booking deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Booking, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>